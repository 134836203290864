<template lang="">
    <div class="xl:flex">
        <SideBar openSub3 />
        <div class="mt-20 px-5 md:px-7 xl:px-9 w-full">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import SideBar from '../../components/SideMenu.vue'

export default defineComponent({
    name: 'FinanceContainer',
    components: {
        SideBar
    },
    data () {
        return {}
    }
})
</script>