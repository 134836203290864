<template>
    <div class="loading-container" v-show="displayAlert">
        <font-awesome-icon icon="spinner" class="mr-2 fa-spin" />
        <span>{{alertMessage}}</span>
    </div>
</template>
<script>
export default {
    name: 'LoadingDialog',
    props: {
        displayAlert: {
            type: Boolean,
            default: false
        },
        alertMessage: {
            type: String,
            default: "Loading data"
        }
    }
}
</script>