import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueDatePicker from '@vuepic/vue-datepicker'
import './index.css'
import '@vuepic/vue-datepicker/dist/main.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faUser, faUsers, faUserPlus, faUserEdit, faLock, faHome, faAngleRight, faAngleLeft, faCubes, faCube, faTags, faFileEdit, faDolly, faFileLines, faTrash, faBell, faCog, faSignOut, faCartArrowDown, faHandHoldingDollar, faSackDollar, faCommentDollar, faTimes, faCheck, faRightToBracket, faSpinner, faEye, faSearch, faPrint, faUndo, faCamera, faCalendar, faArrowRight, faShoppingBasket, faBuilding, faTruck, faTable, faPlus } from '@fortawesome/free-solid-svg-icons'

library.add(faUser, faUsers, faUserPlus, faUserEdit, faLock, faHome, faAngleRight, faAngleLeft, faArrowRight, faCubes, faCube, faTags, faFileEdit, faDolly, faFileLines, faTrash, faBell, faCog, faSignOut, faCartArrowDown, faHandHoldingDollar, faSackDollar, faCommentDollar, faTimes, faCheck, faRightToBracket, faSpinner, faEye, faSearch, faPrint, faUndo, faCamera, faCalendar, faShoppingBasket, faBuilding, faTruck, faTable, faPlus)

createApp(App).component('font-awesome-icon', FontAwesomeIcon).component('VueDatePicker', VueDatePicker).use(store).use(router).mount('#app')
