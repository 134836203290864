import { createStore } from 'vuex'
import createPersistedState from "vuex-plugin-persistedstate"

export default createStore({
  state: {
    pageTitle: "Dashboard",
    user: {
      userId: null,
      userName: null,
      userMail: null,
      userType: null,
      userBranch: null,
      userProfile: null,
      isAuthenticated: false
    }
  },
  getters: {
    getTitle(state){
      return state.pageTitle;
    },
    getYuli(state) {
      return state.user.userMail;
    },
    getUserBranch(state) {
      return state.user.userBranch;
    },
    getUser(state) {
      return state.user;
    },
    getName(state) {
      return state.user.userName
    }
  },
  mutations: {
    setPageTitle(state, newTitle) {
      state.pageTitle = newTitle;
    },
    setProfile(state, picture) {
      state.user.userProfile = picture;
    },
    setUser(state, userData) {
      state.user = {
        userId: userData.uid,
        userName: userData.name,
        userMail: userData.email,
        userType: userData.type,
        userBranch: userData.branch,
        userProfile: userData.profile,
        isAuthenticated: userData.flag
      }
    },
    destroyUser(state) {
      state.user = null;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
