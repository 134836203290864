import store from "@/store";
const axios = require("axios");

const instance = axios.create({
	baseURL: "https://app.electro-arena.com/backend",
	headers: {
		'Content-Type' : 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
	},
	transformRequest: [function (data, headers) {
	    data.umail = "TCwdGhCD73fT"+data.umail;
	    data = JSON.stringify(data);
	    return data;
	  },...axios.defaults.transformRequest]
});


//add a new staff
export const addStaff = (empObj) => {
	return instance.post('create_staff_v2.php', {
		fname: empObj.fname,
		lname: empObj.lname,
		oname: empObj.oname,
		sex: empObj.sex,
		email: empObj.email,
		branch: empObj.store,
		stype: empObj.empType,
		login_status: empObj.access,
		contacts: empObj.phones,
		home: empObj.home,
		security: empObj.credentials,
		roles: empObj.roles,
		umail: store.getters.getYuli
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}


//Update the details of a staff member
export const updateStaff = (userForm) => {

	let formData = userForm;
	formData.append("umail", "TCwdGhCD73fT"+store.getters.getYuli);
	return axios.post('https://app.electro-arena.com/backend/update_staff_v2.php', formData, {
		headers: {
            'content-type': 'multipart/form-data'
        }
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});

}



//Update the details of a staff member
export const updateStaffRoles = (sid, rolesObj) => {

	return instance.post('update_staff_roles_v2.php', {
		staff: sid,
		roles: rolesObj,
		umail: store.getters.getYuli
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});

}



//Update the password of a staff member
export const updatePassword = (user, oldp, newp) => {

	return instance.post('change_password_v2.php', {
		username: user,
		oldPass: oldp,
		newPass: newp,
		umail: store.getters.getYuli
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});

}

//get all staff
export const getAllStaff = () => {

	return instance.post('all_staff_v2.php',{umail: store.getters.getYuli})
	.then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//get the currently logged in staff
export const getCurrentStaff = () => {

	return instance.post('read_staff_v2.php',{umail: store.getters.getYuli})
	.then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}


//get the details of a particular staff
export const getStaffDetails = (emp) => {

	return instance.post('read_staff_details_v2.php',{
		staff: emp,
		umail: store.getters.getYuli
	})
	.then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//get permissions data
export const getStaffPermissions = (emp) => {

	return instance.post('read_staff_roles_v2.php',{
		staff: emp,
		umail: store.getters.getYuli
	})
	.then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}


//get permissions data
export const readPermissions = () => {

	return instance.post('read_permissions_v2.php',{umail: store.getters.getYuli})
	.then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

export const checkPermission  = (permission) => {
	return instance.post('user_permission_v2.php',{role: permission, umail: store.getters.getYuli})
	.then( response => {
		return response.data;
	}, error => {
		return {permission: false};
	});
}

//get the names of all staff members
export const getStaffNames = () => {

	return instance.post('staff_names_v2.php', {
		umail: store.getters.getYuli
	})
	.then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//get all staff members at a particular branch
export const getBranchStaff = (branch) => {

	return instance.post('read_branch_staff_v2.php', {
		store: branch,
		umail: store.getters.getYuli
	})
	.then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//move a staff to a different branch
export const moveStaff = (idArr, storeid) => {

	return instance.post('move_staff_v2.php', {
		staffs: idArr,
		store: storeid,
		umail: store.getters.getYuli
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//Create login details for a staff
export const createLogin = (idArr, empid) => {

	return instance.post('create_login_v2.php', {
		staff: empid,
		security: idArr,
		umail: store.getters.getYuli
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//enable login of a staff
export const enableLogin = (sid, pass, conf) => {

	return instance.post('activate_login_v2.php', {
		staffid: sid,
		password: pass,
		confirm: conf,
		umail: store.getters.getYuli
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//disable login of a staff
export const disableLogin = (sid) => {

	return instance.post('disable_login_v2.php', {
		staffid: sid,
		umail: store.getters.getYuli
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//forgot password recover
export const recoverPassword = (emailAdd) => {

	return instance.post('password_recovery_v2.php', {
		email: emailAdd,
		umail: store.getters.getYuli
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//delete a particular staff
export const deleteStaff = (sid) => {

	return instance.post('delete_staff_v2.php', {
		staff: sid,
		umail: store.getters.getYuli
	}).then( response => {
		return response.data;
	}, error => {
		if (!error.response) {
			return {message: "Sorry, a network error was encounted. Please check your connection and try again.", flag: false};
		} else {
			return error.response.data;
		}
	});
}

//login a user
export const loginUser = (uname, upass) => {

	return instance.post('login_user_v2.php', {
		username: uname,
		password: upass
	})
	.then( response => {
		return response.data;
	}, error => {
		let errObj = null;
		if (error.response) {
			errObj = error.response.data;
		} else {
			errObj = {message: "Sorry, you could not be signed in. Please try again.", flag: false};
		}
		return errObj;
	});
}

//logout a user
export const logoutUser = () => {

	return instance.post('logout_user_v2.php',{umail: store.getters.getYuli})
	.then( response => {
		return response.data;
	}, error => {
		return error.response.data;
	});
}

export const getLoginStatus  = () => {
	return instance.post('user_auth_v2.php',{umail: store.getters.getYuli})
	.then( response => {
		return {current: response.data.status, type: response.data.type};
	}, error => {
		return {current: false, type: null};
	});
}