<template>
    <div :class="alertClass" v-show="displayAlert">
        <font-awesome-icon :icon="alertIcon" class="mr-2" />
        <span>{{alertMessage}}</span>
    </div>
</template>
<script>
export default {
    name: 'AlertDialog',
    props: {
        alertFlag:{
            type: Boolean,
            default: false
        },
        displayAlert: {
            type: Boolean,
            default: false
        },
        alertMessage: {
            type: String,
            default: "Alert message"
        }
    },
    computed: {
        alertClass() {
            if(this.alertFlag) {
                return 'success-alert'
            } else {
                return 'error-alert'
            }
        },
        alertIcon() {
            if(this.alertFlag) {
                return 'check'
            } else {
                return 'times'
            }
        }
    }
}
</script>