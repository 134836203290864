<template>
  <div class="flex justify-center items-center h-screen w-full overflow-hidden bg-gradient-to-br from-transparent via-[#212F3D] to-[#1C2833]">

    <img src="../assets/cover-image.jpg" alt="daabilim" class="absolute -z-20 w-full h-screen">

    <!-- Logoin Part -->
    <div class="px-5 md:px-8 py-8 bg-white shadow-lg shadow-black/30 rounded-md">
      <span class="block text-center text-3xl lg:text-5xl mb-1">Login</span>
      <span class="block text-center text-base lg:text-xl mb-5">Enter your email and password to login.</span>
      <div class="w-full sm:w-[350px] mx-auto">
        <form @submit.prevent="checkUserLogin" class="w-full">
          <!-- Username Field -->
          <div class="bg-white shadow-md shadow-black/30 rounded-md overflow-hidden border border-black/40 flex mb-6">
            <span class="bg-gray-200 px-5 py-3 leading-3"><font-awesome-icon icon="user" class="text-neutral-600" /></span>
            <input type="text" v-model="username" class="bg-transparent border-none outline-none px-5 py-3 w-full" placeholder="username">
          </div>
          <!-- Password Field -->
          <div class="bg-white shadow-md shadow-black/30 rounded-md overflow-hidden border border-black/40 flex mb-6">
              <span class="bg-gray-200 px-5 py-3 leading-3"><font-awesome-icon icon="lock" class="text-neutral-600" /></span>
              <input type="password" v-model="password" class="bg-transparent border-none outline-none px-5 py-3 w-full" placeholder="password">
          </div>
          <div class="mb-6">
            <alert-dialog
              :alert-flag="isLoginSuccessful"
              :alert-message="dialogVariables.dialogMessage"
              :display-alert="dialogVariables.showMessage"
            />
            <loading-dialog
              :display-alert="dialogVariables.isAuthenticating"
              :alert-message="dialogVariables.authMessage"
            />
          </div>
          <button type="submit" class="save-button uppercase w-full shadow-md shadow-black/20 font-medium"><font-awesome-icon icon="right-to-bracket" class="mr-2" :disabled="true" />Login</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import AlertDialog from "../components/AlertDialog.vue"
import LoadingDialog from '@/components/LoadingDialog.vue'
import { loginUser } from '@/services/StaffServices'
import { mapMutations } from 'vuex'

export default defineComponent({
  name: 'HomeView',
  components: {
    AlertDialog,
    LoadingDialog
  },
  data () {
    return {
      username: null,
      password: null,
      isLoginSuccessful: false,
      dialogVariables: {
        isAuthenticating: false,
        showMessage: false,
        authMessage: "Loading data.",
        dialogMessage: "Hi"
      }
    }
  },
  methods: {
    ...mapMutations({
      setUser: "setUser",
      cancelUser: "destroyUser"
    }),
    async checkUserLogin () {

      if(this.dialogVariables.isAuthenticating) {
        return;
      }

      if(this.username === null || this.password === null) {
        this.isLoginSuccessful = false;
        this.dialogVariables = {...this.dialogVariables, dialogMessage: "Please enter your username and password", showMessage: true};
        return;
      }

      if(this.username === "" || this.password === "") {
        this.isLoginSuccessful = false;
        this.dialogVariables = {...this.dialogVariables, dialogMessage: "Please enter your username and password", showMessage: true};
        return;
      }

      this.dialogVariables = {...this.dialogVariables, authMessage: "Authenticating user. Please wait", isAuthenticating: true};

      let userData = null;
      await loginUser(this.username, this.password).then(function(response) {
        userData = response;
      });

      if(userData.flag) {
        this.setUser(userData);
        this.isLoginSuccessful = true;
        this.dialogVariables = {...this.dialogVariables, dialogMessage: userData.message, showMessage: true,  isAuthenticating: false};
        const nextRoute = (userData.type === "Manager") ? "/dashboard" : "/transaction";
        setTimeout(() => {
          this.$router.push(nextRoute);
        }, 1500);
      } else {
        this.cancelUser();
        this.isLoginSuccessful = false;
        this.dialogVariables = {...this.dialogVariables, dialogMessage: userData.message, showMessage: true,  isAuthenticating: false};
        setTimeout(() => {
          this.dialogVariables = {...this.dialogVariables, dialogMessage: "", showMessage: false};
        }, 3000);
      }

    }
  },
  mounted() {
    document.title = "Daabilim - User Login";
  }
})
</script>
